import { BeforeAfterContainer, Square, BeforeAfterProductPanel, BlurOverlayWithImage, ResultsText, SlideBar, Cross, BeforeAfterTopText, BeforeAfterProductContainer, AnimatedDiv, BeforeAfterSmallProductContainer, BeforeAfterClaimsContainer, ClaimContainer, ClaimText, BAClaimsButton } from "../styles/beforeAfterStyle"
import { useTranslation } from "react-i18next"
import cross from '../assets/icons/Cross.svg'
import arrowsIcon from '../assets/icons/BAarrows.png'

import ReactBeforeSliderComponent from '../sliderComponent/index';
import DropDown from 'react-dropdown';
import '../styles/dropdown.css'
import '../styles/carousel.css'
import { MainButton, MainText, Price, ProductName, QuantityButton, QuantityButtonContainer, QuantityText, SPPImagesContainer, SPPImg, SPPSmallImagesContainer, SPPTextsContainer, Tag, TagContainer } from "../styles/spp";
import { CliniqueState, Item, Product, Retailer } from "../type"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { MainButtonText } from "../styles/basics"
import { Price as LightPrice } from "../styles/recoStyle"
import { AnimatePresence } from "framer-motion"
import { getCliniqueWebsiteForImageUrl, translateAnalyseName } from "../constantes/utilitaries"
import { Carousel } from "react-responsive-carousel";
// import phototest from '../assets/Images/photo.png'
import i18n from "../i18nextInit";
import { addToBagWithIframeResizer } from "../constantes/iFrameResizerTest";
import { handleSrc } from "./newResultViews/utils";

interface BeforeAfterProps {
    product: Product,
    selectProduct: (product: Product | null) => void
}

const BeforeAfterView = (props: BeforeAfterProps) => {
    const { t } = useTranslation()
    const effects = useSelector((state: CliniqueState) => state.session.effects)
    const userImg = useSelector((state: CliniqueState) => state.session.imageUser)
    const effectFile = require('../assets/Data/Effect.json')
    const market = useSelector((state: CliniqueState) => state.session.market)
    const [claimsDiv, setClaimsDiv] = useState<any[]>([])
    const [littleTextsDiv, setLittleTextDiv] = useState<any[]>([])
    const [areClaimsOpen, openClaims] = useState<boolean>(true)

    const [effectImg, setEffect] = useState<{ imageUrl: any }>({
        imageUrl: userImg
    })

    const SECOND_IMAGE = {
        imageUrl: userImg
    }

    useEffect(() => {
        if (effects.length > 0) {
            let _url: string = ""
            let _effect = effects.find((effect: { sku: string, imgURL: string }) => effect.sku === props.product.prod_base_id)
            if (_effect) {
                _url = _effect.imgURL
            }

            if (_url !== "") {
                setEffect({ imageUrl: _url })
            }
        }
    }, [effects, props.product.prod_base_id])

    const beforeAfterMarket = () => {
        switch (market.toLowerCase()) {
            case "tw":
            case "hk":
            case "cn":
            case "kr":
            case "jp":
            case "my":
            case "sg":
                return "ASIA"
            default:
                return "EMEA"
        }
    }

    const castBeforeAfterLanguage = (lang: string) => {
        switch (lang) {
            case "EnSg":
            case "EnUk":
            case "EnAu":
            case "EnNz":
            case "EnHk":
            case "EnCa":
            case 'EnHi':
            case "EnZa":
            case "EnMy":
                return "EnUs"
            default:
                return lang
        }
    }

    useEffect(() => {
        let effect: any = effectFile[props.product.prod_base_id]
        if (effect) {
            if (effect.claims) {
                let claims: any[] = []
                let _claimsDiv: any[] = []
                let key: number = 0
                let littleTexts: any[] = []
                let _littleTextsDiv: any[] = []

                effect.claims.forEach((claim: any) => {
                    if (claim[castBeforeAfterLanguage(i18n.language)] && claim["market"].includes(beforeAfterMarket())) {
                        claims.push(claim)
                    }
                });

                effect.littleText.forEach((_littleText: any) => {
                    if (_littleText[castBeforeAfterLanguage(i18n.language)] && _littleText["market"].includes(beforeAfterMarket())) {
                        littleTexts.push(_littleText)
                    }
                });

                console.warn(littleTexts)

                claims.forEach(claim => {
                    _claimsDiv.push(<ClaimContainer
                        key={key}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ height: 0, opacity: 0 }}
                    >
                        <ClaimText istitle>
                            {claim[castBeforeAfterLanguage(i18n.language)].slice(0, 4)}
                        </ClaimText>

                        <ClaimText >
                            {claim[castBeforeAfterLanguage(i18n.language)].slice(4)}
                        </ClaimText>
                    </ClaimContainer>)
                    key++
                })

                littleTexts.forEach(littleText => {
                    _littleTextsDiv.push(<ClaimContainer
                        key={key}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ height: 0, opacity: 0 }}>
                        <ClaimText >
                            {littleText[castBeforeAfterLanguage(i18n.language)]}
                        </ClaimText>
                    </ClaimContainer>)
                    key++
                })

                setLittleTextDiv(_littleTextsDiv)
                setClaimsDiv(_claimsDiv)
            }
        }
        // eslint-disable-next-line
    }, [])


    return (
        <BeforeAfterContainer initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            key="BA_KEY"
            transition={{ type: "spring", duration: 0.3 }}>
            <Cross src={cross} onClick={() => props.selectProduct(null)} />

            <BlurOverlayWithImage>
                <Square />
                <BeforeAfterTopText>
                    {t("before", "Before")}
                </BeforeAfterTopText>

                <BeforeAfterTopText isRight>
                    {t("after")}
                </BeforeAfterTopText>
            </BlurOverlayWithImage>

            <BeforeAfterClaimsContainer>
                <AnimatePresence>
                    {areClaimsOpen && littleTextsDiv}
                    {areClaimsOpen && claimsDiv}
                </AnimatePresence>

                {effectFile[props.product.prod_base_id].claims && <BAClaimsButton onClick={() => {
                    openClaims(!areClaimsOpen)
                }}>
                    {areClaimsOpen ? '-' : '+'}
                </BAClaimsButton>}
            </BeforeAfterClaimsContainer>

            <ReactBeforeSliderComponent
                firstImage={effectImg}
                secondImage={SECOND_IMAGE}
                delimiterIconStyles={{
                    width: window.innerWidth < 768 ? "20px" : "40px",
                    height: window.innerWidth < 768 ? "20px" : "40px",
                    backgroundImage: `url(${arrowsIcon})`,
                    backgroundSize: "cover",
                    position: "absolute",
                    left: window.innerWidth < 768 ? "-9px" : "-20px",
                    bottom: window.innerWidth < 768 ? "calc(50% - 10px)" : "50px"
                }}
            />
            <BeforeAfterProduct product={props.product} />
        </BeforeAfterContainer>
    )
}
interface BeforeAfterProductProps {
    product: Product,
}

export const enum StepProductPage {
    small,
    wide
}

const BeforeAfterProduct = (props: BeforeAfterProductProps) => {
    const { t } = useTranslation()
    const { isMicrosite, market, retailer } = useSelector((state: CliniqueState) => state.session)
    const [quantity, setQuantity] = useState<number>(1)
    const [step, setStep] = useState<StepProductPage>(StepProductPage.small)
    const [images, setImages] = useState<any[]>([])

    const getDefaultActualItem = () => {
        let item: Item | undefined = props.product.items!.find(item => item.sku_id === props.product.instoreSku)

        if (item) {
            return item
        } else {
            return props.product.items![0]
        }
    }

    const [actualItem, setActualItem] = useState<Item>(getDefaultActualItem())
    const sephoraProductsMap = require("../assets/sephora/productsMap.json")
    const MSProductsMap = require("../assets/markAndSpencer/productsMap.json")

    let options: any = []
    if (props.product.category !== "foundation") {
        if (props.product.items![0]) {
            props.product.items!.forEach(item => {
                if (props.product.category === "foundation") {
                    options.push({ value: item.sku_id, label: item.shadeName })
                }
                else if (item.quantity) {
                    options.push({ value: item.sku_id, label: item.quantity })
                }
            });
        }
    } else {
        options = [{ value: actualItem.sku_id, label: actualItem.quantity }]
    }

    const changeItem = (e: any) => {
        setActualItem(props.product.items!.find((item) => item.sku_id === e.value)!)
    }

    useEffect(() => {
        let _images: any[] = []
        let key: number = 0
        _images.push(<SPPImg key={key} style={{ objectFit: "contain" }} src={handleSrc(props.product, market)} />)
        key++
        props.product.images.forEach((image: any) => {
            _images.push(<SPPImg key={key} style={{ objectFit: "cover" }} onError={(e) => e.currentTarget.src = getCliniqueWebsiteForImageUrl(market) + props.product.items![0].image[0].src} src={image.src} />)
            key++
        })
        setImages(_images)
        //eslint-disable-next-line
    }, [])

    const handleExpend = () => {
        if (step === StepProductPage.small) {
            setStep(StepProductPage.wide)
        }

        if (step === StepProductPage.wide) {
            setStep(StepProductPage.small)
        }
    }

    const handleQuantity = (adding?: boolean) => {
        let _quantity = quantity
        if (adding) {
            _quantity++
            setQuantity(_quantity)
        } else {
            _quantity--
            setQuantity(_quantity)
        }
    }

    const handlePrice = (): string => {
        let displayedPrice: string = ""
        if (props.product.items) {
            if (props.product.items[0]) {
                displayedPrice = actualItem.price
            }
        }
        return displayedPrice
    }

    const handleShortDescription = (): string => {
        let displayedDesc: string = ""
        if (props.product.texts) {
            if (props.product.texts.find((text: { label: string, content: string }) => text.label === "short_description")!) {
                displayedDesc = props.product.texts.find((text: { label: string, content: string }) => text.label === "short_description")!.content
            }
        }
        return displayedDesc
    }

    const handleWhoIsFor = (): { label: string, content: string } | null => {
        if (props.product.texts) {
            if (props.product.texts.find((text: { label: string, content: string }) => text.label === "WHO IT IS FOR")) {
                return {
                    label: props.product.texts.find((text: { label: string, content: string }) => text.label === "WHO IT IS FOR")!.label!,
                    content: props.product.texts.find((text: { label: string, content: string }) => text.label === "WHO IT IS FOR")!.content!
                }
            }
        }
        return null
    }

    const handleWhatItIs = (): { label: string, content: string } | null => {
        if (props.product.texts) {
            if (props.product.texts.find((text: { label: string, content: string }) => text.label === "WHAT_IT_IS")) {
                return {
                    label: props.product.texts.find((text: { label: string, content: string }) => text.label === "WHAT_IT_IS")!.label!,
                    content: props.product.texts.find((text: { label: string, content: string }) => text.label === "WHAT_IT_IS")!.content!
                }
            }
        }
        return null
    }

    const handleWhatItdoes = (): { label: string, content: string } | null => {
        if (props.product.texts) {
            if (props.product.texts.find((text: { label: string, content: string }) => text.label === "WHAT_IT_DOES")) {
                return {
                    label: props.product.texts.find((text: { label: string, content: string }) => text.label === "WHAT_IT_DOES")!.label!,
                    content: props.product.texts.find((text: { label: string, content: string }) => text.label === "WHAT_IT_DOES")!.content!
                }
            }
        }
        return null
    }

    const tagHandler = (isSmall?: boolean) => {
        let _tags: any[] = []
        let key = 0
        props.product.tags.forEach((concern) => {
            _tags.push(<Tag key={key}>{translateAnalyseName(t, concern as string, market)}</Tag>)
            key++
        })

        return isSmall ? _tags[0] : _tags
    }

    const handleEnabled = (): boolean => {
        if (!retailer) {
            return true
        }

        if (retailer === Retailer.sephora && sephoraProductsMap[props.product.prod_base_id]) {
            return true
        }

        if (retailer === Retailer.MS && MSProductsMap[props.product.prod_base_id]) {
            return true
        }

        return false
    }

    const handleCTAOnClick = (e: any) => {
        if (retailer === Retailer.sephora) {
            if (sephoraProductsMap[props.product.prod_base_id]) {
                window.open(sephoraProductsMap[props.product.prod_base_id]["link"], "_blank")
            }
        }
        else if (retailer === Retailer.MS) {
            if (MSProductsMap[props.product.prod_base_id]) {
                window.open(MSProductsMap[props.product.prod_base_id]["link"], "_blank")
            }
        }
        else {
            addToBagWithIframeResizer(props.product.skuID)
        }
        e.stopPropagation()
    }

    return (<BeforeAfterProductPanel onClick={() => handleExpend()} step={step}>
        <SlideBar />
        <ResultsText small={step === StepProductPage.small}>{t("results-over-time", "Results over time.")}</ResultsText>
        {window.innerWidth > 768 && <AnimatePresence>
            {step !== StepProductPage.small && <BeforeAfterProductContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <SPPImagesContainer>
                    {step === StepProductPage.wide ? images : <SPPImg src={handleSrc(props.product, market)} />}
                </SPPImagesContainer>

                <SPPTextsContainer>
                    <ProductName dangerouslySetInnerHTML={{ __html: props.product.name }}>
                    </ProductName>

                    <TagContainer>
                        {tagHandler()}
                    </TagContainer>

                    {handleShortDescription() && <MainText dangerouslySetInnerHTML={{ __html: handleShortDescription() }}>
                    </MainText>}

                    {handleWhoIsFor() && <div style={{ display: "flex", gap: "2px", width: "100%" }}>
                        <MainText style={{ fontWeight: 700, margin: 0, textTransform: "capitalize", width: "intial" }}>{t("skin-type")}</MainText>
                        <MainText dangerouslySetInnerHTML={{ __html: handleWhoIsFor()!.content }}>
                        </MainText>
                    </div>}

                    {!isMicrosite && !retailer && <Price>{handlePrice()}</Price>}

                    {!isMicrosite && window.innerWidth < 768 && !retailer && <DropDown arrowClosed={<span className="arrow" />}
                        arrowOpen={<span className="arrow-open" />}
                        menuClassName="dropdown-menu"
                        className="container"
                        options={options}
                        placeholder={actualItem.quantity ? actualItem.quantity : actualItem.sku_id}
                        onChange={(e) => changeItem(e)} />}

                    {!isMicrosite && !retailer && <QuantityButtonContainer>
                        <QuantityText>{t("quantity", "quantity")}</QuantityText>
                        <QuantityButton disabled={quantity <= 1} onClick={(e) => {
                            e.stopPropagation()
                            handleQuantity()
                        }}>-</QuantityButton>
                        <QuantityButton>{quantity}</QuantityButton>
                        <QuantityButton onClick={(e) => {
                            e.stopPropagation()
                            handleQuantity(true)
                        }}>+</QuantityButton>
                    </QuantityButtonContainer>}

                    <div style={{ display: "flex", gap: "8px" }}>
                        {!isMicrosite && <MainButton $enabled={handleEnabled()} whileTap={{ scale: 1.05 }}
                            onClick={(e) => {
                                handleCTAOnClick(e)
                            }}>
                            <MainButtonText>
                                {retailer === Retailer.sephora ? "Voir Produit" : t("add-to-bag")}
                            </MainButtonText>
                        </MainButton>}

                        {!isMicrosite && window.innerWidth >= 768 && step === StepProductPage.wide && <DropDown arrowClosed={<span className="arrow" />}
                            arrowOpen={<span className="arrow-open" />}
                            menuClassName="dropdown-menu"
                            className="container"
                            options={options}
                            placeholder={actualItem.quantity ? actualItem.quantity : actualItem.sku_id}
                            onChange={(e) => changeItem(e)} />}
                    </div>


                    {step === StepProductPage.wide && <AnimatedDiv initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}>

                        {handleWhatItIs() && <MainText style={{ margin: "24px 0px" }} dangerouslySetInnerHTML={{ __html: handleWhatItIs()!.content }}>
                        </MainText>}

                        {handleWhatItdoes() && <MainText style={{ margin: "24px 0px" }} dangerouslySetInnerHTML={{ __html: handleWhatItdoes()!.content }}>
                        </MainText>}
                    </AnimatedDiv>}

                </SPPTextsContainer>
            </BeforeAfterProductContainer>}
        </AnimatePresence>}

        {window.innerWidth > 768 && <AnimatePresence>
            {step === StepProductPage.small && <BeforeAfterProductContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>

                {window.innerWidth > 768 && <SPPImagesContainer style={{ maxWidth: "150px", marginRight: "22px" }}>
                    <SPPImg src={handleSrc(props.product, market)} />
                </SPPImagesContainer>}

                <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "space-between" }}>
                    <ProductName dangerouslySetInnerHTML={{ __html: props.product.name }}>
                    </ProductName>

                    <TagContainer style={{ marginBottom: "initial" }}>
                        {tagHandler()}
                    </TagContainer>
                </div>

                <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "space-between", alignItems: "flex-end" }}>

                    {!isMicrosite && <LightPrice style={{ margin: "0px" }}>{handlePrice()}</LightPrice>}

                    {!isMicrosite && <MainButton $enabled={handleEnabled()} whileTap={{ scale: 1.05 }}
                        onClick={(e) => {
                            handleCTAOnClick(e)
                        }}>
                        <MainButtonText>
                            {retailer === Retailer.sephora ? "Voir Produit" : t("add-to-bag")}
                        </MainButtonText>
                    </MainButton>}
                </div>

            </BeforeAfterProductContainer>}
        </AnimatePresence>}

        {/* Handling Small product for mobile */}
        {window.innerWidth <= 768 && <AnimatePresence>
            {step !== StepProductPage.wide && <BeforeAfterSmallProductContainer
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>

                <div style={{ display: "flex", flexDirection: "row" }}>
                    <SPPSmallImagesContainer style={{ height: "initial" }}>
                        <SPPImg style={{ height: "initial" }} src={handleSrc(props.product, market)} />
                    </SPPSmallImagesContainer>

                    <div style={{ display: "flex", flexDirection: "column", flex: 2, justifyContent: "space-between", height: window.innerWidth < 390 ? "130px" : "145px", padding: "0px 12px", boxSizing: "border-box" }}>
                        <div style={{ display: "flex" }}>
                            <ProductName dangerouslySetInnerHTML={{ __html: props.product.name }}>
                            </ProductName>

                            {!isMicrosite && !retailer && <LightPrice style={{ margin: "0px", paddingLeft: "12px" }}>{handlePrice()}</LightPrice>}
                        </div>

                        <TagContainer style={{ marginBottom: "initial" }}>
                            {tagHandler(true)}
                        </TagContainer>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "start", alignItems: "flex-end", paddingTop: "16px" }}>

                    {handleShortDescription() && step !== StepProductPage.small && <MainText dangerouslySetInnerHTML={{ __html: handleShortDescription() }}>
                    </MainText>}

                    {handleWhoIsFor() && step !== StepProductPage.small &&
                        <div style={{ gap: "2px", width: "100%" }}>
                            <MainText style={{ fontWeight: 700, margin: 0, textTransform: "capitalize", width: "initial" }}>{t("skin-type")}</MainText>
                            <MainText dangerouslySetInnerHTML={{ __html: handleWhoIsFor()!.content }}>
                            </MainText>
                        </div>
                    }

                    {!isMicrosite && <MainButton $enabled={handleEnabled()} whileTap={{ scale: 1.05 }}
                        onClick={(e) => {
                            handleCTAOnClick(e)
                        }}>
                        <MainButtonText>
                            {retailer === Retailer.sephora ? "Voir Produit" : t("add-to-bag")}
                        </MainButtonText>
                    </MainButton>}
                </div>

            </BeforeAfterSmallProductContainer>}

            {step === StepProductPage.wide && <>
                <SPPImagesContainer>
                    {images}
                </SPPImagesContainer>

                {window.innerWidth < 768 && <Carousel dynamicHeight={false} showThumbs={false} showStatus={false}>
                    {images}
                </Carousel>}

                <SPPTextsContainer>
                    <ProductName isWide dangerouslySetInnerHTML={{ __html: props.product.name }}>
                    </ProductName>

                    <TagContainer>
                        {tagHandler()}
                    </TagContainer>

                    {handleShortDescription() && <MainText dangerouslySetInnerHTML={{ __html: handleShortDescription() }}>
                    </MainText>}

                    {handleWhoIsFor() &&
                        <div style={{ gap: "2px", width: "100%" }}>
                            <MainText style={{ fontWeight: 700, margin: 0, textTransform: "capitalize", width: "initial" }}>{t("skin-type")}</MainText>
                            <MainText dangerouslySetInnerHTML={{ __html: handleWhoIsFor()!.content }}>
                            </MainText>
                        </div>
                    }

                    {!isMicrosite && !retailer && <Price>{handlePrice()}</Price>}

                    {!isMicrosite && !retailer && <DropDown arrowClosed={<span className="arrow" />}
                        arrowOpen={<span className="arrow-open" />}
                        menuClassName="dropdown-menu"
                        className="container"
                        options={options}
                        placeholder={actualItem.quantity ? actualItem.quantity : actualItem.sku_id}
                        onChange={(e) => changeItem(e)} />}

                    {!isMicrosite && !retailer && <QuantityButtonContainer>
                        <QuantityText>{t("quantity")}</QuantityText>
                        <QuantityButton disabled={quantity <= 1} onClick={(e) => {
                            handleQuantity()
                            e.stopPropagation()
                        }}>-</QuantityButton>
                        <QuantityButton>{quantity}</QuantityButton>
                        <QuantityButton onClick={(e) => {
                            e.stopPropagation()
                            handleQuantity(true)
                        }}>+</QuantityButton>
                    </QuantityButtonContainer>}

                    {!isMicrosite && <MainButton $enabled={handleEnabled()} whileTap={{ scale: 1.05 }}
                        onClick={(e) => {
                            handleCTAOnClick(e)
                        }}>
                        <MainButtonText>
                            {retailer === Retailer.sephora ? "Voir Produit" : t("add-to-bag")}
                        </MainButtonText>
                    </MainButton>}


                    {handleWhatItIs() && <MainText style={{ margin: "24px 0px" }} dangerouslySetInnerHTML={{ __html: handleWhatItIs()!.content }}>
                    </MainText>}

                    {handleWhatItdoes() && <MainText style={{ margin: "24px 0px" }} dangerouslySetInnerHTML={{ __html: handleWhatItdoes()!.content }}>
                    </MainText>}

                </SPPTextsContainer>
            </>}
        </AnimatePresence>}

    </BeforeAfterProductPanel>)
}

export default BeforeAfterView