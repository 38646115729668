import { CBSKError } from "../api/skinDiag/defaults";
import i18n from "../i18nextInit";
import { Category, ConcernName, Product, SkinAnalyse, SkinType, UVprotection } from "../type";
import { ConcernLevel } from './../type';
import Papa from "papaparse"
const colorDiff = require('color-diff');

export const translateAnalyseName = (t: Function, analyseName: string, market: string): string => {
    switch (analyseName) {
        case "lines_wrinkles":
            return t("lines-wrinkles", "Lines and wrinkles")
        case "pores_uneven_structure":
            return t("pores-uneven-texture", "Pores and uneven texture")
        case "irritation":
            return t("irritation-redness", "Irritation")
        case "blemishes":
            return t(`imperfections-or-${['US', 'NA'].includes(market) ? 'acne' : 'blemishes'}`, "Imperfections and blemishes")
        case "fatigue":
            return t("fatigue", "Fatigue")
        case "dehydration":
            return t("dehydration", "Dehydration")
        case "loss_of_volume":
            return t("loss-volume-sagging", "Loss of volume")
        case "uneven_skintone":
            return t("uneven-skin-tone-dark-spots", "Uneven skin tone")
        default:
            return "ERROR"
    }
}

export const translateError = (t: Function, error: CBSKError) => {
    switch (error) {
        case CBSKError.low_light:
            return t("adjust-lighting")
        case CBSKError.too_close:
        case CBSKError.too_down:
        case CBSKError.too_far:
        case CBSKError.too_left:
        case CBSKError.too_right:
            return t("adjust-eye-level")
        default:
            return t("adjust-eye-level")
    }
}

export const concerntoRecoDescription = (concern: ConcernName, t: Function): string => {
    switch (concern) {
        case ConcernName.BLEMISHES:
            return t("imperfections-acne-desc")
        case ConcernName.DEHYDRATION:
            return t("dehydration-desc")
        case ConcernName.FATIGUE:
            return t("fatigue-desc")
        case ConcernName.IRRITATION:
            return t("irritation-redness-desc")
        case ConcernName.LOSS_OF_VOLUME:
            return t("loss-volume-sagging-desc")
        case ConcernName.PORES:
            return t("pores-uneven-texture-desc")
        case ConcernName.UNEVEN_SKINTONE:
            return t("uneven-skin-tone-dark-spot-desc")
        case ConcernName.WRINKLES:
            return t("lines-wrinkles-global-desc")
        case ConcernName.NONE:
            return "Error"
        default:
            return "Error"
    }
}

export function base64toBlob(base64Data: string, contentType: any) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export function imagedata_to_image(imagedata: any) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = imagedata.width;
    canvas.height = imagedata.height;
    ctx!.putImageData(imagedata, 0, 0);

    var image = new Image();
    image.src = canvas.toDataURL();
    return image;
}

function downloadObjectAsJson(exportObj: any, exportName: string) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    //document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    //downloadAnchorNode.remove();
}

async function fetchCsv() {
    const response = await fetch('langs.csv');
    const reader = response!.body!.getReader();
    const result = await reader.read();
    const decoder = new TextDecoder('utf-8');
    const csv = await decoder.decode(result.value);
    console.log(result)
    return csv;
}

export const parseLanguagesJSON = async () => {
    //let languageJSON: any[] = require("../assets/TradParserTest.json")

    //let locals: {}[] = [{ "EnUs": {} }, { "ArAr": {} }, { "MsMs": {} }, { "CnCn": {} },
    // { "ZhHk": {} }, { "ZhTw": {} }, { "CsCz": {} }, { "DaDk": {} }, { "NlNl": {} }, { "FiFi": {} },
    // { "FrCa": {} }, { "FrFr": {} }, { "DeDe": {} }, { "ElGr": {} }, { "HeIl": {} }, { "HiHi": {} },
    // { "HuHu": {} }, { "InIn": {} }, { "ItIt": {} }, { "JaJp": {} }, { "KoKr": {} }, { "MyMy": {} },
    // { "NoNo": {} }, { "PlPl": {} }, { "PtBr": {} }, { "PtPt": {} }, { "RoRo": {} }, { "RuRu": {} },
    // { "EsMx": {} }, { "EsEs": {} }, { "SvSe": {} }, { "ThTh": {} }, { "TrTr": {} }, { "ViVi": {} }]
    let locals: {}[] = [{ "KoKr": {} }]

    Papa.parse(await fetchCsv(), {
        header: true,
        complete: (results) => {
            setTimeout(() => {
                results.data.forEach((part: any) => {
                    for (let language in part) {
                        let _local: any = {}
                        _local = locals.find((local: any) => Object.keys(local)[0] === language)
                        if (_local) {
                            (_local[language] ??= {})[part.TOKENS] ??= part[language]
                        }
                    }
                })

                for (let i = 0; i < locals.length; i++) {
                    downloadObjectAsJson(locals[i][Object.keys(locals[i])[0] as keyof {}], Object.keys(locals[i])[0])
                }
            }, 4000);
        },
    });
}

export const addToCart = (skuBaseId: string, quantity: number) => {
    // Vérifier si il ne faudrait pas envoyé sans le JSON.stringify
    let postMessage = JSON.stringify({
        "_elc_action": "addtobag",
        "skuBaseId": skuBaseId,
        "INCREMENT": 1,
        "QTY": quantity
    })
    console.log(postMessage)
    window.parent.postMessage(postMessage, "*");
}

export const generateSessionID = (market: string, langue: string): string => {
    let sessionID = "cl-" + market + "-" + langue + "-" + Date.now() + "-" + Math.random().toString(36).substr(2, 9)
    console.log("New Session " + sessionID)
    return sessionID
}

export const sendAnalytics = (action: string, label: string, _sessionID: string) => {
    console.log("ANALYTICS - Send Event as Message : " + action + " -> " + label)
    let sessionId = "wisimage-" + _sessionID

    let message = {
        "messageType": "analytics_event",
        "event_name": "clinicalReality",
        "event_category": "clinical reality",
        "event_action": action,
        "event_label": label,
        'external_session_id': sessionId
    }
    window.parent.postMessage(message, "*");

}
export const sendAnalyticsPageView = (step: number, market: string, _sessionID: string) => {
    console.log("ANALYTICS - Send PageView as Message : Step " + step + " -> " + market)
    let sessionId = "wisimage-" + _sessionID
    let message = {
        "messageType": "analytics_pageView",
        "page_type": "category",
        "page_url": '/clinicalreality/step' + step,
        "page_name": 'Clinical Reality - Step ' + step,
        "boutique": "Clinical Reality - " + market,
        'external_session_id': sessionId
    }

    console.warn(message)
    window.parent.postMessage(message, "*");
}

export const sendAnalyticsComplete = (market: string, _sessionID: string) => {
    console.log("ANALYTICS - Send PageView complete -> " + market)
    let sessionId = "wisimage-" + _sessionID
    let message = {
        "messageType": "analytics_pageView",
        "page_type": "category",
        "page_url": '/clinicalreality/complete',
        "page_name": 'Clinical Reality - complete ',
        "boutique": "Clinical Reality - " + market,
        'external_session_id': sessionId
    }

    console.warn(message)
    window.parent.postMessage(message, "*");
}

export function remove_duplicates_es6(arr: any) {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it) as string[];
}

export const getConcernLevel = (score: number): ConcernLevel => {
    if (score < 0.01) {
        return 'null'
    }
    if (score <= 0.15) {
        return 'veryLow';
    }
    if (score <= 0.3) {
        return 'low';
    }
    if (score <= 0.45) {
        return 'medium';
    }
    return 'high';
}

export const computeDisplayScore = (score: number): number => {
    let amin, amax, bmin, bmax, factor, offset: number

    if (score <= 0.1) {
        amin = 0
        amax = 0.1
        bmin = 0
        bmax = 0.35
    }
    else if (score > 0.1 && score <= 0.2) {
        amin = 0.1
        amax = 0.2
        bmin = 0.35
        bmax = 0.50
    }
    else if (score > 0.2 && score < 0.5) {
        amin = 0.2
        amax = 0.5
        bmin = 0.5
        bmax = 0.75
    }
    else if (score >= 0.5 && score <= 1) {
        amin = 0.5
        amax = 1
        bmin = 0.75
        bmax = 1
    }
    else {
        amin = 0
        amax = 0
        bmin = 0
        bmax = 0
    }

    factor = (bmax - bmin) / (amax - amin)
    offset = bmin - amin * factor

    return score * factor + offset
}

export const closeIFrame = () => {
    window.parent.postMessage("close_clinicalreality_iframe", "*");
}

export const castConcernToAnalyticsConcern = (concernName: ConcernName) => {
    switch (concernName) {
        case ConcernName.BLEMISHES:
            return "Blemishes"
        case ConcernName.DEHYDRATION:
            return "hydration"
        case ConcernName.FATIGUE:
            return "Fatigue"
        case ConcernName.IRRITATION:
            return "Irritation"
        case ConcernName.UNEVEN_SKINTONE:
            return "Uneven Skin tone"
        case ConcernName.LOSS_OF_VOLUME:
            return "Loss Volume & Sagging"
        case ConcernName.PORES:
            return "Pores & Uneven Texture"
        case ConcernName.WRINKLES:
            return "Lines & Wrinkles"
        default:
            return ""
    }
}

export const sendRecommendationAnalytics = (analyses: SkinAnalyse[], _sessionID: string) => {
    let concernArray: any[] = []
    analyses.forEach((analyse) => {
        concernArray.push(analyse.name + "|" + analyse.score)
    })

    let sessionId = "wisimage-" + _sessionID
    let message = {
        "messageType": "analytics_event",
        "event": 'clinicalRealityConcerns',
        "event_category": 'clinical reality',
        "event_action": 'results - concerns',
        "event_label": concernArray.toString(),
        "external_session_id": 'wisimage-' + sessionId
    }
    console.log(message)
    window.parent.postMessage(message, "*");
}

export const sendQuestAnalytics = (skintype: SkinType, uvProtection: UVprotection, _sessionID: string) => {

    let sessionId = "wisimage-" + _sessionID
    let skinTypeMessage = {
        "messageType": "analytics_event",
        event: 'clinicalRealityConcerns',
        event_category: 'clinical reality',
        event_action: 'results - questions',
        event_label: "skintype|" + skintype,
        external_session_id: 'wisimage-' + sessionId
    }

    let uvProtectionMessage = {
        "messageType": "analytics_event",
        event: 'clinicalRealityConcerns',
        event_category: 'clinical reality',
        event_action: 'results - questions',
        event_label: "uvprotection|" + uvProtection,
        external_session_id: 'wisimage-' + sessionId
    }

    console.log(skinTypeMessage)
    console.log(uvProtectionMessage)
    window.parent.postMessage(skinTypeMessage, "*");
    window.parent.postMessage(uvProtectionMessage, "*");
}

export const sendProductsAnalytics = (recommendationForOneConcern: any, _sessionID: string) => {

    let prodEventData: string[] = []
    let impressions: any[] = []
    let key: number = 0
    recommendationForOneConcern.mainProducts.forEach((product: Product) => {
        //eslint-disable-next-line
        prodEventData.push("baseID: " + 'PROD' + product.prod_base_id + " | " + "SKUID: " + "SKU" + product.skuID)
        impressions.push({
            id: product.prod_base_id,
            list: "/clinicalreality/results",
            position: key
        })
        key++
    });

    recommendationForOneConcern.otherProducts.forEach((product: Product) => {
        //eslint-disable-next-line
        if (product.category === Category.foundation) {
            prodEventData.push("PROD" + product.prod_base_id)
        } else {
            //eslint-disable-next-line
            prodEventData.push("baseID: " + 'PROD' + product.prod_base_id + " | " + "SKUID: " + "SKU" + product.skuID)
        }

        impressions.push({
            id: product.prod_base_id,
            list: "/clinicalreality/results",
            position: key
        })
        key++
    });

    let sessionId = "wisimage-" + _sessionID
    let message = {
        "messageType": "analytics_event",
        event: 'clinicalRealityProducts',
        event_category: 'clinical reality',
        event_action: 'results - products',
        event_label: prodEventData.toString(),
        external_session_id: 'wisimage-' + sessionId,
        ecommerce: {
            impressions: impressions
        }
    }
    console.log(message)
    window.parent.postMessage(message, "*");
}

export const castTRMarketForELC = (market: string) => {
    switch (market) {
        case 'TRUSA':
            return "US"
        case "TREMEA":
            switch (i18n.language) {
                case "FrFr":
                    return "FR"
                default:
                    return "FR"
            }
        case "TRUK":
            return "UK"
        case "TRDE":
            return "DE"
        default:
            return market.slice(2)
    }
}

export const getCliniqueWebsiteForImageUrl = (market: string): string => {
    switch (market) {
        case "TRES":
        case "ES":
        case "SP":
            return "https://www.clinique.es/"
        case "FR":
            return "https://www.clinique.fr/"
        case "TRUK":
        case "TRIE":
        case "UK":
        case "IE":
        case "GB":
            return "https://www.clinique.co.uk/"
        case "TRBE":
        case "BE":
            return "https://www.clinique.be/"
        case "TRAT":
        case "AT":
            return "https://www.clinique.at/"
        case "TRCH":
        case "CH":
            return "https://www.ch.clinique.com/"
        case "TRCZ":
        case "CZ":
            return "https://www.clinique.cz/"
        case "TRDE":
        case "DE":
            return "https://www.clinique.de/"
        case "TRGR":
        case "GR":
            return "https://www.clinique.gr/"
        case "TRHU":
        case "HU":
            return "https://www.clinique.hu/"
        case "TRIL":
        case "IL":
            return "https://www.clinique.co.il/"
        case "TRIN":
        case "IN":
            return "https://www.clinique.in/"
        case "TRIT":
        case "IT":
            return "https://www.cliniqueitaly.it/"
        case "TRNL":
        case "NL":
            return "https://www.clinique.nl/"
        case "TRDK":
        case "DK":
            return "https://www.clinique.dk/"
        case "TRNO":
        case "NO":
            return "https://www.clinique.no/"
        case "TRSE":
        case "SE":
            return "https://www.clinique.se/"
        case "TRPL":
        case "PL":
            return "https://www.clinique.com.pl/"
        case "RO":
            return "https://www.clinique.ro/"
        case "TRTR":
        case "TR":
            return "https://www.clinique.com.tr/"
        case "ZA":
            return "https://www.clinique.co.za/"
        case "TRFI":
        case "FI":
            return "https://www.clinique.fi/"
        case "TRAU":
        case "AU":
            return "https://www.clinique.com.au/"
        case "TRNZ":
        case "NZ":
            return "https://www.clinique.co.nz/"
        case "TRHK":
        case "HK":
            return "https://www.clinique.com.hk/"
        case "TRJP":
        case "JP":
            return "https://www.clinique.jp/"
        case "TRKR":
        case "KR":
            return "https://www.cliniquekorea.co.kr/"
        case "TRMY":
        case "MY":
            return "https://www.clinique.com.my/"
        case "TRTH":
        case "TH":
            return "https://www.clinique.co.th/"
        case "TRTW":
        case "TW":
            return "https://www.clinique.com.tw/"
        case "TRCL":
        case "CL":
            return "https://www.clinique.cl/"
        case "TRBR":
        case "BR":
            return "https://www.clinique.com.br/"
        case "TRLATAM":
        case "MX":
            return "https://www.clinique.com.mx/"
        case "CA":
            return "https://www.clinique.ca/"
        case "CN":
            return "https://www.clinique.com.cn"
        case "TRASIA":
            switch (i18n.language) {
                case "ZhHk":
                    return "https://www.clinique.com.hk/"
                case "CnCn":
                    return "https://www.clinique.com.cn"
                default:
                    return "https://www.clinique.com.hk/"
            }
        case "TREMEA":
            switch (i18n.language) {
                case "FrFr":
                    return "https://www.clinique.fr/"
                default:
                    return "https://www.clinique.fr/"
            }
        default:
            return "https://www.clinique.com/"
    }
}

export const filterOTSTCConcernImage = async (sourceImage: ImageData, otstcImage: ImageData, otstcColor: string, drawnColor: string, deltaE: number = 0): Promise<ImageData> => {
    if (otstcImage.width !== sourceImage.width || otstcImage.height !== sourceImage.height) {
        throw new Error("Source and OTSTC images must have the same dimensions");
    }

    const otstcColorRGBA = hexToRGBA(otstcColor);
    const drawnColorRGBA = hexToRGBA(drawnColor);

    const outputImage = new ImageData(new Uint8ClampedArray(sourceImage.data), sourceImage.width, sourceImage.height);

    for (let i = 0; i < otstcImage.data.length; i += 4) {
        const otstcPixel = {
            r: otstcImage.data[i],
            g: otstcImage.data[i + 1],
            b: otstcImage.data[i + 2],
            a: otstcImage.data[i + 3],
        };
        if (otstcPixel.a === 0) {
            continue;
        }
        if (colorMatches(otstcPixel, otstcColorRGBA, deltaE)) {
            const outputPixelIndex = i / 4;
            const drawnPixel = {
                r: drawnColorRGBA.r,
                g: drawnColorRGBA.g,
                b: drawnColorRGBA.b,
                a: drawnColorRGBA.a,
            };
            outputImage.data[outputPixelIndex * 4] = drawnPixel.r;
            outputImage.data[outputPixelIndex * 4 + 1] = drawnPixel.g;
            outputImage.data[outputPixelIndex * 4 + 2] = drawnPixel.b;
            outputImage.data[outputPixelIndex * 4 + 3] = drawnPixel.a;
        }
    }

    return outputImage;
};

const hexToRGBA = (hex: string) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
        a: 255,
    };
};

const colorMatches = (color: any, targetColor: any, deltaE: number) => {
    const colorLab = colorDiff.rgba_to_lab(color);
    const targetColorLab = colorDiff.rgba_to_lab(targetColor);
    const diff = colorDiff.diff(colorLab, targetColorLab);
    return diff <= deltaE;
};

export const isLongLanguage = () => {
    const longLanguages: string[] = ["HuHu", "RuRu", "EsMx", "ElGr", "NoNo", "EsCl", "EsEs"]
    if (longLanguages.includes(i18n.language)) {
        return true
    }
    return false
}

export const getYourResultsLink = (market: string): string => {
    switch (market) {
        default:
            return getCliniqueWebsiteForImageUrl(market) + "/account/diagnostics.tmpl"
    }
}
